import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DOMAIN_NAME, MATRIMONY_USER_DETAIL, MATRIMONY_SHOW_INTEREST } from "../../Constant";
import LoadingDetail from "../UX/LoadingDetail";
import { Link } from "react-router-dom";
const MatrimonyUserDetail = () => {

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [userDetail, setUsersData] = useState([]);
  const [userMatrimonyId, setUserMatrimonyId] = useState(id);
  const [userAcceptanceOrRejectionStatus, setUserAcceptanceOrRejectionStatus] = useState(null);
  const [status, setStatus] = useState('Show Interest')

  async function getUserDetails(id) {
    const token = localStorage.getItem("token");
    const response = await fetch(DOMAIN_NAME + MATRIMONY_USER_DETAIL + id , {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`, // Adding the auth token to headers
                    }});
    const data = await response.json();
    console.log(data?.data)
    setUserAcceptanceOrRejectionStatus(data?.status)
    setUsersData(data?.data);
  }

  async function sendInterest() {

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(DOMAIN_NAME +  MATRIMONY_SHOW_INTEREST, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'target_user': userMatrimonyId })
      })

      if (response.ok) {
        alert('Successfully shown interest');
        setStatus('Proposal sent')

      } else {
        alert('Internal Server error');
      }

    } catch(e) {
      console.error('Error: ', e)
    }

    

  }


  useEffect(() => {
    getUserDetails(id);
  }, []);

  return userDetail.length == 0 ? (
    <LoadingDetail />
  ) : (
    <div className="flex justify-center m-4">
      <div className="card lg:card-side bg-base-100 shadow-xl w-4/5">
        <div className="carousel carousel-center bg-neutral rounded-box max-w-md space-x-4 p-4">
          <div className="carousel-item">
            <img
              src={`${DOMAIN_NAME + userDetail?.user_image_1}`}
              className="rounded-box w-80 h-96"
            />
          </div>
          <div className="carousel-item">
            <img
              src={`${DOMAIN_NAME + userDetail?.user_image2}`}
              className="rounded-box w-80 h-96"
            />
          </div>
          <div className="carousel-item">
            <img
              src={`${DOMAIN_NAME + userDetail?.user_image3}`}
              className="rounded-box w-80 h-96"
            />
          </div>
          <div className="carousel-item">
            <img
              src={`${DOMAIN_NAME + userDetail?.user_image4}`}
              className="rounded-box w-80 h-96"
            />
          </div>
          <div className="carousel-item">
            <img
              src={`${DOMAIN_NAME + userDetail?.user_image5}`}
              className="rounded-box w-80 h-96"
            />
          </div>
        </div>
        <div className="card-body">
          <h2 className="card-title">{userDetail?.user?.full_name}</h2>
          <p><span className="font-bold">Martial Status :</span> {userDetail?.marital_status}</p>
          <p><span className="font-bold">Education :</span> {userDetail?.highest_qualification}</p>
          <p><span className="font-bold">Profession :</span> {userDetail?.profession}</p>
          <p><span className="font-bold">Current Company :</span> {userDetail?.current_company}</p>
          <p><span className="font-bold">Family Name :</span> {userDetail?.family_name}</p>
          <p><span className="font-bold">Gender :</span> {userDetail?.gender}</p>
          <p><span className="font-bold">Mother Family Name :</span> {userDetail?.mother_family_name}</p>  

          {userDetail?.user?.mobile_number && (
           <p><span className="font-bold">Phone number :</span> {userDetail.user.mobile_number}</p>
          )}

          {
            userAcceptanceOrRejectionStatus == 'rejected' ?
            (
              <div className="card-actions justify-end">
              <Link>
                <button className="btn btn-error disabled:cursor-not-allowed">You're already rejected by user</button>
              </Link>
              </div>

            ) : userAcceptanceOrRejectionStatus == 'accepted' ? (
              <div className="card-actions justify-end">
              <Link>
                <button className="btn btn-success disabled:cursor-not-allowed">You both are mutually accepted the proposals</button>
              </Link>
              </div>
            ) : userAcceptanceOrRejectionStatus == 'proposed' ? (
              <div className="card-actions justify-end">
              <Link>
                <button className="btn btn-warning disabled:cursor-not-allowed">Proposay sent / may have recieved (check Notifications)</button>
              </Link>
              </div>
            ) : (
              <div className="card-actions justify-end">
                      <Link onClick={sendInterest}>
                        <button className="btn">{status}</button>
                      </Link>
              </div>
              )
          }

        </div>
      </div>
    </div>
  );
};

export default MatrimonyUserDetail;
