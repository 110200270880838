import { useState } from "react";
import { DOMAIN_NAME, BUSINESS_REGISTER } from "../../Constant";
import BusinessTermsOfUser from "../Footer/BusinessTermsOfUser"

const BusinessRegister = () => {
  const [businessImages, setBusinessImages] = useState(''); //corresponds to shop image 1
  const [shopImage2, setShopImage2] = useState('');
  const [shopImage3, setShopImage3] = useState('');
  const [shopImage4, setShopImage4] = useState('');
  const [verificationImage, setVerificationImages] = useState('');
  const [fullName, setFullName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [typeOfBusiness, setTypeOfBusiness] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [status, setStatus] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('Register');


  const clearState = () => {
    setBusinessImages('');
    setShopImage2('');
    setShopImage3('');
    setShopImage4('');
    setVerificationImages('');
    setFullName('');
    setBusinessName('');
    setTypeOfBusiness('');
    setMobileNumber('');
    setEmail('');
    setBusinessAddress('');
    setGstNumber('');
    setStatus('');
    setBusinessImages('');
    setVerificationImages('');
    setSubmitButtonText('Register')
  }



  const handleBusinessFileUpload = (e) => {
    setBusinessImages(e.target.files[0]);
  };

  const handleVerificationImageUpload = (e) => {
    setVerificationImages(e.target.files[0]);
  };

  const handleBusinessFileUpload2 = (e) => {
    setShopImage2(e.target.files[0]);
  };

  const handleBusinessFileUpload3 = (e) => {
    setShopImage3(e.target.files[0]);
  };

  const handleBusinessFileUpload4 = (e) => {
    setShopImage4(e.target.files[0]);
  };

  
  
  

  const handleRegister = async (e) => {
    e.preventDefault();
    setSubmitButtonText('Submitting... May take 30 seconds')
    try {
      
      const formData = new FormData();
      formData.append('full_name', fullName);
      formData.append('mobile_number', mobileNumber);
      formData.append('type_of_buisness', typeOfBusiness);
      formData.append('business_name', businessName);
      formData.append('email', email);
      formData.append('business_address', businessAddress);
      formData.append('verification_image', verificationImage);
      formData.append('shop_image1',  businessImages);
      formData.append('shop_image2',  shopImage2);
      formData.append('shop_image3',  shopImage3);
      formData.append('shop_image4',  shopImage4);
      formData.append('gst_number', gstNumber);
      
      const response = await fetch(DOMAIN_NAME + BUSINESS_REGISTER, 
        {
          method: 'POST',
          body: formData
        }
      )
      const data = await response.json();
      
      if (response?.ok) {
        clearState();
        alert("Registration Sucessfull, Login after verification")
        window.location.reload();
      }
      
      if (!response.ok) {
        alert("Registration Failed");
        setStatus('Registration failed');
        setSubmitButtonText('Register again');
      }

    } catch (error) {
      console.log(error)
    }

  }



  return (
    <>
      <div className="lg:m-6 md:m-6 sm:m-6 min-h-svh">

        <form onSubmit={handleRegister}>
          <p className="text-green-700 font-medium text-2xl">{status}</p>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="full_name"
                className="block mb-2 text-sm font-medium  "
              >
                Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="full_name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Businees Name"
                className="block mb-2 text-sm font-medium  "
              >
                Businees Name <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Businees Name"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Type of Business"
                className="block mb-2 text-sm font-medium  "
              >
                Type of Business <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Type of Business"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={typeOfBusiness}
                onChange={(e) => setTypeOfBusiness(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Mobile Number"
                className="block mb-2 text-sm font-medium  "
              >
                Mobile Number <span className="text-main-color">*</span>
              </label>
              <input
                type="number"
                id="Mobile Number"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Email"
                className="block mb-2 text-sm font-medium  "
              >
                Email <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Email"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Business Address"
                className="block mb-2 text-sm font-medium  "
              >
                Business Address <span className="text-main-color">*</span>
              </label>
              <input
                type="text"
                id="Business Address"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="GST Number"
                className="block mb-2 text-sm font-medium  "
              >
                GST Number
              </label>
              <input
                type="text"
                id="GST Number"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="Pan / Aadhar / Voter id for verification"
                className="block mb-2 text-sm font-medium  "
              >
                Pan / Aadhar / Voter id for verification{" "}
                <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="Pan / Aadhar / Voter id for verification"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleVerificationImageUpload}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="business photos"
                className="block mb-2 text-sm font-medium  "
              >
                Vypara photo 1 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="business photos"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleBusinessFileUpload}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="business photos"
                className="block mb-2 text-sm font-medium  "
              >
                Vypara photo 2 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="business photos"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={handleBusinessFileUpload2}
                required
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="business photos"
                className="block mb-2 text-sm font-medium  "
              >
                Vypara photo 3 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="business photos"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleBusinessFileUpload3}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="business photos"
                className="block mb-2 text-sm font-medium  "
              >
                Vypara photo 4 <span className="text-main-color">*</span>
              </label>
              <input
                type="file"
                id="business photos"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 -700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handleBusinessFileUpload4}
                accept="image/jpeg, image/png, image/gif, image/jpg"
              />
            </div>



          </div>


          <div className="flex items-start mb-6">
            <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium  dark:text-gray-300"
            >
              <span className="text-black">By Registering you're agreeing with </span>
              
              
                <label htmlFor="my_modal_7" className="btn">terms and conditions</label>
                <input type="checkbox" id="my_modal_7" className="modal-toggle" />
                <div className="modal" role="dialog">
                    <div className="modal-box w-full">
                      <h1 className="text-main-color">Vyapara Terms and Conditions</h1>
                      <BusinessTermsOfUser />
                      <label htmlFor="my_modal_7" className="btn">Close</label>
                    </div>
                    <label className="modal-backdrop" htmlFor="my_modal_7">Close</label>
                </div>
            </label>
          </div>
          <button type="submit" className="btn bg-main-color btn-ghost">
            {submitButtonText}
          </button>
        </form>
      </div>
    </>
  );
};

export default BusinessRegister;
