import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DOMAIN_NAME, GET_OTP, POST_OTP } from "../../Constant";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    try {
      if (otp) {
        e.preventDefault();
        const loginData = {
          email: email,
          otp: otp,
        };
        const response = await fetch(DOMAIN_NAME + POST_OTP, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        });
        const data = await response.json();
        console.log(response, data);
        if (response.ok) {
          localStorage.setItem("token", data.data.access_token);
          window.location.href = "/";
        }
        if (!response.ok) {
          setStatus(data.message);
        }
      } else {
        setStatus("enter otp");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOTP = async (e) => {
    try {
      if (email) {
        setShowSubmitButton(true);
        const response = await fetch(DOMAIN_NAME + GET_OTP + `?email=${email}`);
        const data = await response.json();
        console.log(response, data);
        if (response.ok) {  
          setStatus(data?.message);
        }
        if (!response.ok) {
          setStatus(data?.message);
        }
      } else {
        setStatus("please enter email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-center items-center h-1/2 m-10 mb-[430] rounded-lg">
          <div
            className={`bg-main-color  pl-[2%] pr-[2%] pt-[1%] pb-[1%] lg:w-[50%] md:w-[75%] shadow-2xl`}
          >
            <p className="p-2 pl-0 font-bold border-dashed">
              Login to Continue
            </p>
            <p>{status}</p>
            <form onSubmit={handleSubmit}>
              {!showSubmitButton && (
                <input
                  type="text"
                  placeholder="Email"
                  className="input input-bordered w-full  block p-2 mb-2"
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}

              {showSubmitButton && (
                <input
                  type="text"
                  placeholder="OTP"
                  className="input input-bordered w-full  block p-2 mb-2"
                  onChange={(e) => setOTP(e.target.value)}
                />
              )}

              {showSubmitButton && (
                <button className="btn hover:btn-outline w-full p-2 mb-2">
                  Enter OTP
                </button>
              )}
            </form>
            {!showSubmitButton && (
              <button
                className="btn hover:btn-outline w-full p-2 mb-2"
                onClick={getOTP}
              >
                Get OTP
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
