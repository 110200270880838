// import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { DOMAIN_NAME, LIST_BUSINESS, LIST_BUSINESS_V2 } from "../../Constant";
// import BusinessListCard from "./BusinessListCard";

// const Business = () => {
//   const [businessList, setBusinessList] = useState("Loading");

//   useEffect(() => {
//     const fetchBusinessList = async () => {
//       const response = await fetch(DOMAIN_NAME + LIST_BUSINESS_V2);
//       const data = await response.json();
//       console.log(data, "line 13");
//       setBusinessList(data?.business_details);
//     };
//     fetchBusinessList();
//   }, []);

//   return (
//     <>
//       <div role="alert" className="alert ">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           className="stroke-info h-6 w-6 shrink-0"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//           ></path>
//         </svg>
//         <span>In order to list your business please register</span>
//         <div>
//           <Link to="/business/register">
//             <button className="btn bg-main-color btn-ghost">Register</button>
//           </Link>
//         </div>
//       </div>

//       <div className="p-[2%] flex justify-center bg-cover flex-wrap bg-gradient-to-r from-main-color to-red-300">
//         {businessList === "Loading" ? (
//           <h1>Loading...</h1>
//         ) : businessList.length === 0 ? (
//           <div className="p-[2%] flex justify-center min-h-screen bg-cover">
//             <h1 className="font-bold">No Business Found</h1>
//           </div>
//         ) : (
//           businessList.map((business, index) => (
//             <BusinessListCard
//               key={index}
//               name={business?.business_name}
//               shopImage={business?.shop_image1}
//               id={business?.id}
//             />
//           ))
//         )}
//       </div>
//     </>
//   );
// };

// export default Business;

import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { DOMAIN_NAME, LIST_BUSINESS_V2 } from "../../Constant";
import BusinessListCard from "./BusinessListCard";

const Business = () => {
  const [businessList, setBusinessList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchBusinessList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${DOMAIN_NAME}${LIST_BUSINESS_V2}?page=${page}&page_size=10`
      );
      const data = await response.json();
      
      if (data?.business_details?.length > 0) {
        setBusinessList(prev => [...prev, ...data.business_details]);
        setHasMore(data.has_next);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching businesses:", error);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchBusinessList();
  }, [fetchBusinessList]);

  // Infinite scroll handler
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 20 && !loading && hasMore) {
      setPage(prev => prev + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div role="alert" className="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info h-6 w-6 shrink-0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>In order to list your business please register</span>
        <div>
          <Link to="/business/register">
            <button className="btn bg-main-color btn-ghost">Register</button>
          </Link>
        </div>
      </div>
      
      <div className="p-[2%] flex justify-center bg-cover flex-wrap bg-gradient-to-r from-main-color to-red-300">
        {businessList.length === 0 && !loading ? (
          <div className="p-[2%] flex justify-center min-h-screen bg-cover">
            <h1 className="font-bold">No Business Found</h1>
          </div>
        ) : (
          businessList.map((business, index) => (
            <BusinessListCard
              key={`${business?.id}-${index}`}
              name={business?.business_name}
              shopImage={business?.shop_image1}
              id={business?.id}
            />
          ))
        )}
        {loading && (
          <div className="w-full text-center py-4">
            <h1>Loading...</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default Business;
