import Carousel from "./Carousel";
import Marquee from "./Marquee";
import Testimonials from "./Testimonials";
import RegisterForBusiness from "./RegisterForBusiness";
import {useState, useEffect} from 'react';

const About = () => {
  const [isVisible,
    setIsVisible] = useState({heritage: false, history: false, modern: false});

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(prev => ({
            ...prev,
            [entry.target.id]: true
          }));
        }
      });
    }, {threshold: 0.1});

    document
      .querySelectorAll('.animate-section')
      .forEach(section => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-slate-50">
      {/* Hero Section with Marquee */}

      {/* Main Content with Interactive Sections */}
      <div className="flex flex-col items-center p-[4%] space-y-12">
        {/* Heritage Section */}
        
        <div
          id="history"
          className={`w-[90%] max-w-7xl card bg-base-100 shadow-2xl overflow-hidden animate-section ${isVisible.history
          ? 'animate-slide-up'
          : 'opacity-0 translate-y-10'}`}>
          <div className="card-body bg-gradient-to-r from-main-color to-to-color">
            <h2 className="card-title text-5xl mb-6">About Us</h2>
            <div className="space-y-6">
              <p className="font-medium text-3xl leading-relaxed">
              The Kodagu Heggade is one of the principal Kodagu language speaking communities in Kodagu. Presently, the Kodagu language is popularly known as Kodava language.
              </p>
              <p className="font-medium text-3xl leading-relaxed">
              According to the 19th-century Kittel dictionary, 'Heggade' means "hiridaada gade" (ಹಿರಿದಾದ ಗಡೆ), which translates to Senior leader. "Hiridada" means big or senior, and "Gade" means leader (ಮುಖ್ಯಸ್ಥ). In old Kannada, Heggade was pronounced as Peggade (ಪಿರಿದಾದ ಗಡೆ), and the term Heggade is synonymous with Peggade.


              </p>
            </div>
          </div>
        </div>

        {/* Historical Background */}
        <div
          id="history"
          className={`w-[90%] max-w-7xl card bg-base-100 shadow-2xl overflow-hidden animate-section ${isVisible.history
          ? 'animate-slide-up'
          : 'opacity-0 translate-y-10'}`}>
          <div className="card-body bg-gradient-to-r from-main-color to-to-color">
            <h2 className="card-title text-5xl mb-6">Our History</h2>
            <div className="space-y-6">
              <p className="font-medium text-3xl leading-relaxed">
              This community, along with 21 other Kodagu-speaking inhabitant communities, shares a common language, tradition, rituals, attire, and festivals in the Kodagu region since time immemorial. However, some historical records suggest that traces of the Heggades can be found in the Chengalva and Kongalva principalities, which existed approximately 1000 years ago.


              </p>
              <p className="font-medium text-3xl leading-relaxed">
              It is generally believed that the Heggades were a tax-collecting class (later caste) until the advent of Haider Ali in the Kodagu province in the late 18th century. According to late 18th-century European writings, Haider Ali replaced the revenue-collecting communities in Kodagu. It is possible that the Heggades lost their tax collection responsibilities and remained agriculturists and warriors since they have held Jamma lands for centuries. In the 25th Hukumnama of 1811, Kodagu King Lingaraj Wodeyar exempted the Heggades, Amma Kodavas (vegetarian agriculturists), Mukkatis (temple servants and agriculturists), and Kodavas (agriculturists) from paying caste taxes to the principality. Kodagu Heggades were one of the privileged communities during the reign of the Lingayat Kings of Coorg. However, they lost their significance soon after the British annexation of Kodagu in 1834. Some Heggades served in the British administration as officials, though not in higher positions.


              </p>
            </div>
          </div>
        </div>

        {/* Modern Era */}
        <div
          id="modern"
          className={`w-[90%] max-w-7xl card bg-base-100 shadow-2xl overflow-hidden animate-section ${isVisible.modern
          ? 'animate-fade-in'
          : 'opacity-0'}`}>
          <div className="card-body bg-gradient-to-r from-main-color to-to-color">
            <h2 className="card-title text-5xl mb-6">Modern Times</h2>
            <div className="space-y-6">
              <p className="font-medium text-3xl leading-relaxed">
              The prosperity of the community has not changed drastically even after independence. The Heggades were included in the 3A OBC category along with other forward communities until the 1990s. They were listed in the 2A OBC category after the intervention of community leaders. The community's fortunes have improved somewhat since globalization, with some youth securing jobs in private and corporate sectors rather than public establishments. However, the community is gradually rejuvenating and striving for greater prosperity at present.


              </p>

            </div>
          </div>
        </div>

        {/* Testimonials */}
       
         
       
      </div>
      <Testimonials/>

      
    </div>
  );
};

export default About;