// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { DOMAIN_NAME, LIST_USERSV1 } from "../../Constant";
// import MatrimonyUserUnsubscribed from "../MatrimonyUserProfile/MatrimonyUserUnsubscribed";
// import LoadingDetail from "../UX/LoadingDetail";
// import MatrimonyListCard from "./MatrimonyListCard";


/**
 * 
 * name, age, family name, father's name , mother's name
 */

// const MatrimonyListUser = () => {
//   const [showLandingPage, setShowLandingPage] = useState(false);
//   const [usersData, setUsersData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {

//     const token = localStorage.getItem("token");

//     if (!token) {
//       setShowLandingPage(true);
//       setLoading(false);
//     }

//     if (token) {
//       const fetchUsers = async () => {
//         try {
//           const response = await fetch(DOMAIN_NAME + LIST_USERSV1, {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           });

//           console.log(response)
          
//           if (!response.ok) {
//             setLoading(false);
//             setShowLandingPage(true);
//           }

//           if (response.ok) {
//             const json = await response.json();
//             setUsersData(json?.data);
//             setShowLandingPage(false);
//             setLoading(false)
//           }

//         } catch {}
//       };
//       fetchUsers();
//     }
//   }, []);

//   return loading ? (
//     <LoadingDetail />
//   ) : showLandingPage ? (
//     <MatrimonyUserUnsubscribed />
//   ) : (
//     <div className="flex justify-center flex-wrap">
//       {
//         usersData.map((user, index) => (
//         <MatrimonyListCard key={index} user={user}/>
//       ))
//       }
      
//     </div>
//   );
// };

import React, { useEffect, useState, useCallback } from "react";
import { DOMAIN_NAME, LIST_USERSV1 } from "../../Constant";
import MatrimonyUserUnsubscribed from "../MatrimonyUserProfile/MatrimonyUserUnsubscribed";
import LoadingDetail from "../UX/LoadingDetail";
import MatrimonyListCard from "./MatrimonyListCard";

const MatrimonyListUser = () => {
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchUsers = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setShowLandingPage(true);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${DOMAIN_NAME}${LIST_USERSV1}?page=${page}&page_size=10`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setLoading(false);
        setShowLandingPage(true);
        return;
      }

      const json = await response.json();
      setUsersData(prev => [...prev, ...json.data]);
      setHasMore(json.has_next);
      setShowLandingPage(false);
      setLoading(false);
    } catch {
      setLoading(false);
      setShowLandingPage(true);
    }
  }, [page]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 20 &&
      !loading &&
      hasMore
    ) {
      setPage(prev => prev + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return loading && page === 1 ? (
    <LoadingDetail />
  ) : showLandingPage ? (
    <MatrimonyUserUnsubscribed />
  ) : (
    <div className="flex justify-center flex-wrap">
      {usersData.map((user, index) => (
        <MatrimonyListCard key={`${user.id}-${index}`} user={user} />
      ))}
      {loading && page > 1 && (
        <div className="w-full text-center py-4">
          <LoadingDetail />
        </div>
      )}
    </div>
  );
};

export default MatrimonyListUser;


