import React from "react";

const ReportAbuse = () => {
  return (
    <>
      <p className="py-4 text-black">
      We have certain set of policies which needs to be abided by the users using the website. Any misuse of the website can be reported to us via kodaguheggade@gmail.com 
Please make sure all the supporting documents are attached while reporting to us so that apt action can be taken on the offender.

      </p>
      <p className="py-4 text-black">
      The following scenarios can be reported:

 

      </p>
      <p className="py-4 text-black">
      1)If a member sends obscene or unruly e-mails harassing a person
     </p>
     <p className="py-4 text-black">
     2)If a photography of the user is misused by a member.
     </p>
     <p className="py-4 text-black">
     3)Any fraudulent, offensive or suspicious activities can be notified to us with supporting documents so that swift action will be taken.

     </p>
      
      
    </>
  );
};

export default ReportAbuse;
