import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Logo from "../Footer/assets/logo.png";

// const DetailsDropdown = ({summary, children}) => {
//   const detailsRef = useRef(null);

//   useEffect(() => {
//     const handleClick = (event) => {
//       // Close if clicking outside or if clicking on a link/li/ul inside
//       if (detailsRef.current && (!detailsRef.current.contains(event.target) || event.target.tagName.toLowerCase() === 'a' || event.target.closest('ul'))) {
//         detailsRef
//           .current
//           .removeAttribute('open');
//       }
//     };

//     document.addEventListener('click', handleClick);

//     return () => {
//       document.removeEventListener('click', handleClick);
//     };
//   }, []);

//   return (
//     <details ref={detailsRef}>
//       <summary>{summary}</summary>
//       {children}
//     </details>
//   );
// };

const DetailsDropdown = ({summary, children}) => {
  const detailsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close if clicking outside
      if (detailsRef.current && !detailsRef.current.contains(event.target)) {
        setIsOpen(false);
        detailsRef.current.removeAttribute('open');
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleToggle = (event) => {
    event.preventDefault(); // Prevent default details element behavior
    const newState = !isOpen;
    setIsOpen(newState);
    
    if (newState) {
      detailsRef.current?.setAttribute('open', '');
    } else {
      detailsRef.current?.removeAttribute('open');
    }
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
    detailsRef.current?.removeAttribute('open');
  };

  return (
    <details ref={detailsRef}>
      <summary onClick={handleToggle}>{summary}</summary>
      <ul onClick={handleMenuItemClick}>
        {children}
      </ul>
    </details>
  );
};

const Navbar = () => {

  const [hasToken,
    setHasToken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('token');
    setHasToken(!!token);
  }, []);

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    setHasToken(false);
    // Redirect to login page
    navigate('/matrimony/login');
  };

  return (
    <div className="navbar bg-main-color">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex="0" role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"/>
            </svg>
          </div>
          <ul
            tabIndex="0"
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 text-[16px]">
            <li className="font-bold">
              <Link to="/">Home</Link>
            </li>
            <li className="font-bold">
              <Link to="/about">About</Link>
            </li>

            <li className="font-bold z-20">
              <DetailsDropdown summary="Business">
                <ul className="p-2">
                  <li className="font-bold">
                    <Link to="/business">Business Listings</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/business/dashboard">Dashboard</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/business/register">Register</Link>
                  </li>
                </ul>
              </DetailsDropdown>
            </li>

            <li className="font-bold">
              <Link to='/event'>Events</Link>
            </li>

            <li className="font-bold z-20">
              <DetailsDropdown summary="Matrimony">
                <ul className="p-2">
                  <li className="font-bold">
                    <Link to="/matrimony/listusers">Profiles</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/matrimony/dashboard">Dashboard</Link>
                  </li>
                  <li className="font-bold">
                    <Link to="/matrimony/register">Register</Link>
                  </li>
                </ul>
              </DetailsDropdown>
            </li>

            <li className="font-bold">
              <Link to="/matrimony/interest">Notifications</Link>
            </li>

            <li className="font-bold">
              <Link to='/subscription'>Pricing</Link>
            </li>
          </ul>
        </div>
        <img src={Logo} className="h-12" alt="Flowbite Logo"/>
        <span className="self-center text-4xl font-semibold whitespace-nowra">
          KODAGU HEGGADE
        </span>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 text-2xl">
          <li className="font-bold">
            <Link to="/">Home</Link>
          </li>

          <li className="font-bold">
            <Link to="/about">About</Link>
          </li>

          <li className="font-bold z-20">
            <DetailsDropdown summary="Business">
              <ul className="p-2">
                <li className="font-bold">
                  <Link to="/business">Business Listings</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/dashboard">Dashboard</Link>
                </li>
                <li className="font-bold">
                  <Link to="/business/register">Register</Link>
                </li>
              </ul>
            </DetailsDropdown>
          </li>

          <li className="font-bold">
            <Link to="/event">Events</Link>
          </li>

          <li className="font-bold z-20 dropdown">
            <DetailsDropdown summary="Matrimony">
              <ul className="p-2">
                <li className="font-bold">
                  <Link to="/matrimony/listusers">Profiles</Link>
                </li>
                <li className="font-bold">
                  <Link to="/matrimony/dashboard">Dashboard</Link>
                </li>
                <li className="font-bold">
                  <Link to="/matrimony/register">Register</Link>
                </li>
              </ul>
            </DetailsDropdown>
          </li>

          <li className="font-bold">
            <Link to="/matrimony/interest">Notifications</Link>
          </li>

          <li className="font-bold">
            <Link to="/subscription">Pricing</Link>
          </li>
        </ul>
      </div>
      <div className="navbar-end">
        {hasToken
          ? (
            <button className="btn" onClick={handleLogout}>
              Logout
            </button>
          )
          : (
            <Link className="btn" to="/matrimony/login">
              Login
            </Link>
          )}
      </div>
    </div>
  );
};

export default Navbar;